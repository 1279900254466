import React from "react"
import { Styled } from "theme-ui"

import Layout from "@layouts/form"

export default () => (
  <Layout title="Rejestracja">
    <Styled.h4
      style={{
        color: `#000`,
        fontSize: `22px`,
        lineHeight: `25px`,
        textAlign: `center`,
      }}
    >
      Dziękujemy za rejestrację
    </Styled.h4>
    <Styled.p
      style={{
        letterSpacing: `0px`,
        color: `#7D7D7D`,
        fontSize: `13px`,
        lineHeight: `25px`,
        fontFamily: `Poppins`,
        fontWeight: 400,
        textAlign: `center`,
        paddingTop: '2rem',
        paddingBottom: '2rem'
      }}
    >
      Aby dokończyć rejestrację proszę sprawdź swoją skrzynkę e-mailową. 
      Jeśli wszystko poszło dobrze powinieneś dostać wiadomość z linkiem potwierdzającym Twoją rejestrację.
    </Styled.p>
  </Layout>
)
